import React from "react";
import { Helmet } from "react-helmet"; // Import Helmet for managing head tags

class Header extends React.Component {
  render() {
    return (
      <div>
        <Helmet>
          <link rel="preconnect" href="https://fonts.gstatic.com" />
          <link href="https://fonts.googleapis.com/css2?family=Righteous&display=swap" rel="stylesheet" />
          <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
          <title>JML Digital - Real Estate Media in Central Florida</title>
          <meta name="description" content="JML Digital is a full service real estate photography and video company." />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        </Helmet>

        <header className="header">
        </header>
      </div>
    );
  }
}

export default Header;



      