import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const ElevateYourListings = () => {
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div>
            <div className='title'>
                Elevate Your Listings
            </div>
            <div className='secondtitle'>Professional Real Estate Photography & Videography</div>
            <div className='allblogs'>
                <img src='images/elevateyourlistings.jpeg' alt="Refinished Bathroom" />
                <div>
                    <p>In today's digital-first real estate market, first impressions aren't just made at the front door—they're made online. As a realtor, you know that stunning visuals can make the difference between a quick sale and a listing that lingers. That's where professional real estate photography and videography become your secret weapons for success.</p>

                    <h5>Why Professional Photography Matters</h5>
                    <p>Studies show that listings with professional photos sell up to 32% faster and often command higher prices. In an era where 97% of home buyers begin their search online, your listing photos are essentially your property's digital curb appeal.</p>

                    <h5>The Power of Video Tours</h5>
                    <p>Virtual tours and property videos have become essential tools in modern real estate marketing. Our professional videography services help you:</p>
                    <div className="lists">
                        <ul>
                            <li>Reduce unnecessary showings by pre-qualifying truly interested buyers</li>
                            <li>Reach out-of-town buyers who can't visit in person</li>
                            <li>Create compelling social media content</li>
                            <li>Showcase neighborhood amenities and lifestyle features</li>
                            <li>Stand out from competitors with cinematic property tours</li>
                        </ul>
                    </div>

                    <h5>Our Professional Services Include:</h5>
                    <p>1. Photography Packages</p>
                    <div className="lists">
                        <ul>
                            <li>Interior and exterior shots</li>
                            <li>Aerial drone photography</li>
                            <li>Twilight sessions</li>
                            <li>HDR photography for challenging lighting conditions</li>
                        </ul>
                    </div>

                    <p>2. Video Solutions</p>
                    <div className="lists">
                        <ul>
                            <li>Walkthrough tours</li>
                            <li>Drone footage</li>
                            <li>Neighborhood highlight reels</li>
                            <li>Social media snippets</li>
                            <li>Custom marketing videos</li>
                        </ul>
                    </div>

                    <p>3. Quick Turnaround</p>
                    <div className="lists">
                        <ul>
                            <li>Next-day delivery for photos</li>
                            <li>48-72 hour delivery for video content</li>
                            <li>Rush services available</li>
                        </ul>
                    </div>

                    <h5>Investment in Success</h5>
                    <p>Think of professional real estate photography and videography not as an expense, but as an investment in your listing's success and your personal brand. Our high-quality visuals help you:</p>
                    <div className="lists">
                        <ul>
                            <li>Attract more qualified buyers</li>
                            <li>Reduce time on market</li>
                            <li>Potentially increase sale prices</li>
                            <li>Build your reputation as a premium agent</li>
                            <li>Create lasting marketing materials for your portfolio</li>
                        </ul>
                    </div>

                    <h5>Ready to Elevate Your Listings?</h5>
                    <div className='buttcenterlog'>
                <a href='http://order.jmldigital.com/order' target='_blank' rel="noreferrer">
                    <motion.button 
                        whileHover={{scale: 1.1}}
                        className='book-now-button'  
                    >
                        Book My Shoot
                    </motion.button>
                </a>
                </div>
                    <div className="space"></div>
                  
                    <div className="navigation-arrows">
                        <Link to="/making-space-look-best" className="arrow left-arrow" onClick={scrollToTop}>
                            &lt; Previous
                        </Link >
                        <Link to="/blog" className="bottom-all-blogs" onClick={scrollToTop}>
                       All Blogs
                        </Link>
                        <Link to="/capturing-success" className="arrow right-arrow" onClick={scrollToTop}>
                            Next &gt;
                        </Link>
                    </div>
                    
            </div>
        </div>
        </div>
    );
}

export default ElevateYourListings;
