import React from 'react';

const Jordan = () => {
    return (
        <div><h1>About</h1>
        <div className="jordan-section">
        <div className="jordan-image">
                <img src="/images/Jordan.png" alt="Jordan Michael Lugenbeel" />
            </div>
            <div className="jordan-content">
    <h1>Jordan Michael Lugenbeel</h1>
    <p>
        Hello, my name is Jordan and my only goal is to make your listings shine here is Central Florida. I team up with real estate agents to highlight each property's strengths with professional photography and videography. 
    <p>    
        I help agents see a 30% increase in viewings and sell homes 25% faster on the Space Coast, Orlando, and Tampa.
    </p>
    </p>
    <p>
        Every home has a story, and I love capturing that with stunning visuals. Let’s work together to showcase your properties in the best light.
    </p>
    <p>
        Thank you for considering me as your partner in real estate sales!
    </p>
</div>
            
        </div>
        </div>
    );
};

export default Jordan;
