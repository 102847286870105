import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react'; 
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper'; 
import 'swiper/swiper-bundle.min.css'; 
import { motion } from 'framer-motion';

const slides = [
  {
    wideImage: 'images/Slide1.jpeg',
    tallImage: 'images/Slide1-tall.jpg',
    title: 'Captivate Buyers',
    description: 'Eye-catching visuals that attract attention and boost leads by 30%',
},
{
    wideImage: 'images/Slide2.jpeg',
    tallImage: 'images/Slide2-tall.jpg',
    title: 'Showcase Quality',
    description: 'Professional photography that highlights the unique features of your listings',
},
{
    wideImage: 'images/Slide3.JPG',
    tallImage: 'images/Slide3-tall.jpg',
    title: 'Stand Out',
    description: 'Compelling visuals that create a lasting impression on prospective buyers',
},
{
    wideImage: 'images/Slide4.jpg',
    tallImage: 'images/Slide4-tall.jpg',
    title: 'Schedule Your Shoot',
    description: 'Quick 24-hour turnaround. Book your session even last minute',
},

];

const Slideshow = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 700);

  // Update the state when the screen width changes
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 700);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="slideshow-container">
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]} 
        spaceBetween={50}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        autoplay={{ delay: 7000, disableOnInteraction: false }}
        loop={true} 
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index} className="slide">
            <img 
              src={isMobile ? slide.tallImage : slide.wideImage} 
              alt={slide.title} 
              className="slide-image" 
            />
            <div className="slide-content">
              <h2 className="slide-title">{slide.title}</h2>
              <p className="slide-description">{slide.description}</p>
              <div className='buttcenter'>
                 <a href='http://order.jmldigital.com/order' target='_blank' rel="noreferrer">
                    <motion.button 
                        whileHover={{scale: 1.1}}
                        className='book-now-button'  
                    >
                        Book My Shoot
                    </motion.button>
                </a>  
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Slideshow;
