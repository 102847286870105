import React, { useEffect } from 'react'; // Import useEffect
import Header from './Components/Header.js';
import Menu from './Components/Menu';
import Footer from './Components/Footer.js';
import Front from './Components/Front.js';
import Blog from './Components/Blog.js';
import CapturingSuccess from './Components/CapturingSuccess.js';
import ElevateYourListings from './Components/ElevateYourListings.js';
import MakingSpaceLookBest from './Components/MakingSpaceLookBest.js';
import './App.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4'; // Make sure you have react-ga4 installed

const TRACKING_ID = "G-5FQHMVCVGC"; // Replace with your Measurement ID
ReactGA.initialize(TRACKING_ID);

const App = () => {
  const location = useLocation(); // Use the useLocation hook

  useEffect(() => {
    // Track page views on route changes
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]); // Add location as a dependency

  return (
    <div className="App">
      <Header />
      <Menu />
      <main>
        {/* Place Routes here to keep the layout persistent */}
        <Routes location={location}>
          <Route exact path="/" element={<Front />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/capturing-success" element={<CapturingSuccess />} />
          <Route path="/making-space-look-best" element={<MakingSpaceLookBest />} />
          <Route path="/elevate-your-listings" element={<ElevateYourListings />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

const Root = () => (
  <Router>
    <App />
  </Router>
);

export default Root;
