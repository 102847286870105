import React from 'react';
import Slideshow from './Slideshow';
import Services from './Services';
import Jordan from './Jordan';

const Front = () => {
    return (
        <div>
           
            <div id="slideshow"><Slideshow /></div>
           
            <img src="images/JMLHex2.png" alt="JML Digital" />
            <div className="space"></div>
            <div className='title2' id="jordan"><Jordan /></div>
            <img src="images/JMLHex3.png" alt="JML Digital" />
            <div className="space"></div>
            <div className='title2' id="services"><Services /></div>
        </div>
    );
}

export default Front;

