import React from 'react';
import ReactPlayer from 'react-player';

const Services = () => {
    return (
<div>
<h1 className='services'>Services</h1>
<div className="services-container">
    {/* Photography Section */}
    <div className="service-column">
        <h1 className="servtitle">Photo</h1>
        <img src="images/photoimg.png" className='servimg' loading='lazy' alt="Real Estate Photography" />
        <p className='servdes'>
            JML Digital specializes in stunning property imagery that showcases unique features. Our expert photographers deliver high-quality photos that can increase buyer interest by 30%.
            <ul>
                <li>Skilled photographers with a flair for storytelling</li>
                <li>High-quality images that elevate your listings</li>
                <li>Artistic and technical skills combined</li>
                <li>Transforming spaces into captivating visuals</li>
            </ul>
            Discover how our photography can elevate your listings!
        </p>
    </div>

    {/* Videography Section */}
    <div className="service-column">
        <h1 className="servtitle">Video</h1>
        <div className="youtube-video-container">
            <ReactPlayer
                url='https://www.youtube.com/watch?v=WKQkXgLFquo'
                className='youtube-video'
                width='100%'
                height='100%'
            />
        </div>
        <p className='servdes'>
            Bring your listings to life with captivating video content. Homes with video listings receive 403% more inquiries than those without.
            <ul>
                <li>High-definition videos showcase property charm</li>
                <li>Cinematic walkthroughs engage viewers</li>
                <li>Dynamic content designed to grab attention</li>
                <li>Videos that elevate your listings and visibility</li>
            </ul>
            Join us in redefining real estate marketing with videos that connect!
        </p>
    </div>

    {/* Drone Section */}
    <div className="service-column">
        <h1 className="servtitle">Drone</h1>
        <img src="images/droneimg.png" className='servimg' loading='lazy' alt="Real Estate Drone Aerial" />
        <p className='servdes'>
            Our aerial photography and videography provide breathtaking views that redefine showcasing properties. Listings with drone footage see a 68% increase in engagement.
            <ul>
                <li>High-tech drones delivering stunning visuals</li>
                <li>Licensed pilots ensuring captivating storytelling</li>
                <li>Perfect for luxury estates and commercial properties</li>
                <li>Aerial imagery that enhances marketing efforts</li>
            </ul>
            Elevate your strategy with aerial visuals that stand out!
        </p>
    </div>

    {/* 2D, 3D & Virtual Staging Section */}
    <div className="service-column">
        <h1 className="servtitle">Virtual</h1>
        <img src="images/3dimg.png" className='servimg' loading='lazy' alt="Real Estate Virtual Staging" />
        <p className='servdes'>
            Our 2D and 3D renderings and virtual staging boost your property's marketability. Homes with virtual staging can sell 73% faster than those that don’t.
            <ul>
                <li>2D renders provide a clear view of space</li>
                <li>3D renders immerse viewers in experience</li>
                <li>Virtual staging attracts buyers effectively</li>
                <li>Innovative solutions expedite the sales process</li>
            </ul>
            Unlock your property's potential with our creative services!
        </p>
    </div>
</div>

        </div>
    );
}

export default Services;