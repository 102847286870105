import React, { useEffect, useState } from 'react';
import { send } from 'emailjs-com';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { motion } from 'framer-motion';
import FormikControl from './FormikControl'
import Success from './Success.js';

           

            const initialValues = {
                name: '',
                email: '',
                message: ''
            }

            const validationSchema = Yup.object({
                name: Yup.string().min(3, 'Too Short').required('Required!'),
                email: Yup.string().email('Must be a valid email').required('Required!'),
                message: Yup.string()
            })
                
            
            
function Footer () {
    
    const [showSuccess, setshowSuccess] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setshowSuccess(false)
        }, 3000)
      })
    
    return (  
        <div>
            <div className="space"></div>

<img src="images/JMLHex2.png" alt="JML Digital" />

<div className="space"></div>
            <div className="third">
                <h3>
                    Contact
                </h3>
                <div className='buttcenterlog'>
                <a href='http://order.jmldigital.com/order' target='_blank' rel="noreferrer">
                    <motion.button 
                        whileHover={{scale: 1.1}}
                        className='book-now-button'  
                    >
                        Book My Shoot
                    </motion.button>
                </a>
                </div>
                
                <h4>
                <center>
                <i className="material-icons">
                    phone_iphone
                </i>
                    (719) 761-5250 
                    &nbsp;
                    </center>
                </h4> 
                <h4>
                <center>
                <i className="material-icons">
                    mail
                </i>
                    Jordan@jmldigital.com
                    &nbsp;
                </center>
                </h4>
                
                
            </div>


            <div className="twothird">
            <Formik 
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                validateOnMount
            >

            {formik => {
            return (
                <Form>

                    <FormikControl
                        control='input'
                        type='text'
                        name='name'
                        id="input"
                        placeholder='Your Name'
                    />
                <br/>
                    <FormikControl 
                        control='input'
                        type="text" 
                        name="email" 
                        id="input" 
                        placeholder='Your Email'  
                    />
                <br/>
                    <FormikControl
                        control="textarea"
                        name="message" 
                        id="inputarea" 
                        placeholder='Your Message' 
                    />
                <br/>                   
                    <motion.button 
                        whileHover={{scale: 1.1}}
                        type='submit' 
                        className='form-submit-button'
                        disabled={!formik.isValid || formik.isSubmitting}
                        onClick={() => {
                            setshowSuccess(true)
                          }}
                    >
                        Submit
                    </motion.button>

                    <motion.button 
                        whileHover={{scale: 1.1}}
                        type='reset' 
                        className='form-reset-button'
                    >
                        Reset
                    </motion.button>
                    <Success showSuccess={showSuccess} setshowSuccess={setshowSuccess}/>

                </Form>
                )}}
            </Formik>
            </div>

        <div className="space"></div>

            
            
                
 
            <img src="images/JMLHex4.png" alt="JML Digital" />
            <div className="smtopbot">
            <p><center>
            <i className="material-icons">
                    copyright
                </i>
                    JML Digital 2023
                
                </center>
                </p>
                </div>
        
        <div className="space"></div>

        </div>
    );
}

const onSubmit = (values, onSubmitProps) => {
    send(
        'service_091fs2o',
        'template_48i2drn',
        values,
        'KXY2mPxlRh7xjZ5AF'
    )
    .then((response) => {
    console.log('SUCCESS!', response.status, response.text);
    })
    .catch((err) => {
    console.log('FAILED...', err);
    })
    onSubmitProps.resetForm()
}

export default Footer
