import React from 'react'

const Blog = () => {   


    
    return (
        <div>
           
            <div className='title'>
                JML Digital Blog
            </div>
            <div className='allblogs'>
            
            <a href='/making-space-look-best'>
                <div className='oneblog'>
                    <div className='blogtitle'>Making Every Space Look Its Best</div>
                    <div className='blog2ndtitle'>The Art of Real Estate Photography</div>
                    <img src='images/makingspacelookbest.JPG'alt="Furnished Living Room"/>
                    <div>
                        <p>In a crowded real estate market, your listings need to do more than just show a space—they need to tell a story. As professional real estate photographers, we've mastered the art of transforming ordinary rooms into extraordinary showcases that captivate potential buyers from the very first.... <div className="blue">READ MORE</div>
                        </p>
                   
                </div>
                </div>
                </a>
            <a href='/elevate-your-listings'>
                <div className='oneblog'>
                    <div className='blogtitle'>Elevate Your Listings</div>
                    <div className='blog2ndtitle'> Professional Real Estate Photography & Videography</div>
                    <img src='images/elevateyourlistings.jpeg'alt="Row House/ Town Home For Sale"/>
                    <div>
                        <p>In today's digital-first real estate market, first impressions aren't just made at the front door—they're made online. As a realtor, you know that stunning visuals can make the difference between a quick sale and a listing that lingers. That's where professional real estate photography and... <div className="blue">READ MORE</div>
                        </p>
                   
                </div>
                </div>
                </a>
                <a href='/capturing-success'>
                <div className='oneblog'>
                    <div className='blogtitle'>Capturing Success</div>
                    <div className='blog2ndtitle'> The Importance of High-Quality Visuals in Real Estate Marketing</div>
                    <img src='images/CapturingSuccessImage.jpg'alt="Row House/ Town Home For Sale"/>
                    <div>
                        <p>In the fast-paced world of real estate, first impressions matter. With the majority of homebuyers starting their property search online, the visual presentation of a listing plays a pivotal role in capturing their attention and interest. In this blog post, we delve into the crucial aspect of real estate marketing... <div className="blue">READ MORE</div>
                        </p>
                    </div>
                    </div>
                    </a>
                   
                
            </div>
        
        </div>

        )}
 
export default Blog